import { MRT_ColumnDef, MRT_EditActionButtons, MRT_Row, MRT_TableOptions, MaterialReactTable, useMaterialReactTable, } from "material-react-table"
import { useEffect, useMemo, useState } from "react"
import { Box, Button, DialogActions, DialogContent, DialogTitle, IconButton, Tooltip } from "@mui/material"
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import {
    QueryClient,
    QueryClientProvider,
    useMutation,
    useQuery,
    useQueryClient,
} from 'react-query';
import axios from "axios";

type VerticalType = {
    id: number,
    vertical_name: string
}

function VerticalsTable() {
    const [validateErrors, setValidateErrors] = useState<Record<string, string | undefined>>({})
    const [vertical, setVertical] = useState([])

    useEffect(() => {
        axios.get('https://api.analcustdev.com/vertical/get-all').then((res) => setVertical(res.data))
        console.log('verticals: ', vertical)

    }, [])

    const columns = useMemo<MRT_ColumnDef<VerticalType>[]>(
        () => [
            {
                accessorKey: 'id',
                header: 'Id',
                enableEditing: false,

            },
            {
                accessorKey: 'vertical_name',
                header: 'Вертикаль',
                enableEditing: true,
                muiEditTextFieldProps: {
                    required: false,
                    error: !!validateErrors?.vertical,
                    helperText: validateErrors?.vertical,

                    onFocus: () => {
                        setValidateErrors({
                            ...setValidateErrors,
                            vertical: undefined
                        })
                    }
                },
            },
        ], [validateErrors]
    )

    const {
        data: fetchedVerticals = [],
        isError: isLoadingVerticalsError,
        isFetching: isFetchingVerticals,
        isLoading: isLoadingVerticals,
    } = useGetVertical()

    const { mutateAsync: createVertical, isLoading: isCreatingVertical } = useCreateVertical();
    const { mutateAsync: updateVertical, isLoading: isUpdatingVertical } = useUpdateVertical();
    const { mutateAsync: deleteVertical, isLoading: isDeletingVertical } = useDeleteVertical();



    const handleCreateVertical: MRT_TableOptions<VerticalType>['onCreatingRowSave'] = async ({
        values,
        table
    }) => {
        const newValidateErrors = validateVertical(values);
        if (Object.values(newValidateErrors).some((error) => error)) {
            setValidateErrors(newValidateErrors)
            return
        }
        setValidateErrors({})
        await createVertical(values)
        table.setCreatingRow(null)
    }

    const handleSaveVertical: MRT_TableOptions<VerticalType>['onEditingRowSave'] = async ({
        values,
        table,
    }) => {
        const newValidateErrors = validateVertical(values)
        if (Object.values(newValidateErrors).some((error) => error)) {
            setValidateErrors(newValidateErrors)
            return
        }
        setValidateErrors({})
        await updateVertical(values)
        table.setEditingRow(null)
    }

    const openDeleteConfirmModal = (row: MRT_Row<VerticalType>) => {
        if (window.confirm('Вы уверены, что хотите удалить эту вертикаль?')) {
            deleteVertical(row.original.id)
        }
    }

    const table = useMaterialReactTable({
        columns,
        data: fetchedVerticals,
        createDisplayMode: 'modal',
        editDisplayMode: 'modal',
        enableEditing: true,
        // getRowId: (row: VerticalType) => row.id,
        muiToolbarAlertBannerProps: isLoadingVerticalsError
            ? {
                color: 'error',
                children: 'Ошибка загрузки данных'
            }
            : undefined,
        muiTableContainerProps: {
            sx: {
                minHeight: '500px'
            }
        },
        onCreatingRowCancel: () => setValidateErrors({}),
        onCreatingRowSave: handleCreateVertical,
        onEditingRowCancel: () => setValidateErrors({}),
        onEditingRowSave: handleSaveVertical,

        renderCreateRowDialogContent: ({ table, row, internalEditComponents }) => (
            <>
                <DialogTitle variant="h3">Создать новую вертикаль</DialogTitle>
                <DialogContent
                    sx={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}
                >
                    {internalEditComponents}
                </DialogContent>
                <DialogActions>
                    <MRT_EditActionButtons variant="text" table={table} row={row} />
                </DialogActions>
            </>
        ),

        renderEditRowDialogContent: ({ table, row, internalEditComponents }) => (
            <>
                <DialogTitle variant="h3">Изменить вертикаль</DialogTitle>
                <DialogContent
                    sx={{ display: 'flex', flexDirection: 'column', gap: '1.5rem' }}
                >
                    {internalEditComponents}
                </DialogContent>
                <DialogActions>
                    <MRT_EditActionButtons variant="text" table={table} row={row} />
                </DialogActions>
            </>
        ),
        renderRowActions: ({ row, table }) => (
            <Box sx={{ display: 'flex', gap: '1rem' }}>
                <Tooltip title="Изменить">
                    <IconButton onClick={() => table.setEditingRow(row)}>
                        <EditIcon />
                    </IconButton>
                </Tooltip>
                <Tooltip title="Удалить">
                    <IconButton color="error" onClick={() => openDeleteConfirmModal(row)}>
                        <DeleteIcon />
                    </IconButton>
                </Tooltip>
            </Box>
        ),
        renderTopToolbarCustomActions: ({ table }) => (
            <Button
                variant="contained"
                onClick={() => {
                    table.setCreatingRow(true)
                }}
            >
                Создать новую вертикаль
            </Button>
        ),
        state: {
            isLoading: isLoadingVerticals,
            isSaving: isCreatingVertical || isUpdatingVertical || isDeletingVertical,
            showAlertBanner: isLoadingVerticalsError,
            showProgressBars: isFetchingVerticals
        },
    })

    function useUpdateVertical() {
        const url: string = 'https://api.analcustdev.com/vertical/edit'
        const queryClient = useQueryClient();
        return useMutation({
            mutationFn: async (vertical: VerticalType) => {
                await axios({
                    method: 'PUT',
                    url: url,
                    data: {
                        id: vertical.id,
                        vertical_name: vertical.vertical_name,
                    }
                })
            },

            onMutate: (newVerticalInfo: VerticalType) => {
                queryClient.setQueryData(['vertical'], (prevVerticals: any) =>
                    prevVerticals?.map((prevVertical: VerticalType) =>
                        prevVertical.id === newVerticalInfo.id ? newVerticalInfo : prevVertical,
                    ),
                );
            },
        });
    }

    function useCreateVertical() {
        const url: string = 'https://api.analcustdev.com/vertical/create'
        const queryClient = useQueryClient();
        return useMutation({
            mutationFn: async (vertical: VerticalType) => {
                //zapros budet tuta
                // await new Promise((resolve) => setTimeout(resolve, 1000));
                // return Promise.resolve();

                await axios({
                    method: 'POST',
                    url: url,
                    data: {
                        vertical_name: vertical.vertical_name,


                    }
                }
                )
            },

            onMutate: (newVerticalInfo: VerticalType) => {
                queryClient.setQueryData(
                    ['vertical'],
                    (prevVerticals: any) =>
                        [
                            ...prevVerticals,
                            {
                                ...newVerticalInfo,
                                id: (Math.random() + 1)
                            }
                        ] as VerticalType[]
                )
            }
        })
    }

    return (
        <MaterialReactTable table={table} />
    )
}



function useGetVertical() {
    const url: string = 'https://api.analcustdev.com/vertical/get-all'

    return useQuery<VerticalType[]>({
        queryKey: ['vertical'],
        queryFn: async () => {
            const res = await axios({
                method: 'GET',
                url: url,

            })
            return Promise.resolve(res.data)
        },
        refetchOnWindowFocus: false,
    })
}



function useDeleteVertical() {
    const queryClient = useQueryClient()
    return useMutation({
        mutationFn: async (verticalId: number) => {
            await axios({
                method: 'DELETE',
                url: `https://api.analcustdev.com/vertical/remove/${verticalId}`,
            })
        },
        onMutate: (verticalId: number) => {
            queryClient.setQueryData(['vertical'], (prevVerticals: any) =>
                prevVerticals?.filter((vertical: VerticalType) => vertical.id !== verticalId),
            )
        },
    })
}

const queryClient = new QueryClient();

const VerticalTableWithProviders = () => (
    <QueryClientProvider client={queryClient}>
        <VerticalsTable />
    </QueryClientProvider>
)

export default VerticalTableWithProviders;

const validateRequired = (value: string) => !!value.length

function validateVertical(vertical: VerticalType) {
    return {
        vertical: !validateRequired(vertical.vertical_name)
            ? 'Vertical is required' : '',

    }
}