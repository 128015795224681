import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { Link } from "react-router-dom";

export default function Header() {
    return (
        <Box sx={{ flexGrow: 1 }}>
            <AppBar position="static">
                <Toolbar>
                    <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                        UniAff Questions
                    </Typography>
                    <Link to='/table'>
                        <Button sx={{ color: '#FFFFFF' }}>Таблица</Button>
                    </Link>
                    <Link to='/'>
                        <Button sx={{ color: '#FFFFFF' }}>Вопросы</Button>
                    </Link>
                    <Link to='/vertical-table'>
                        <Button sx={{ color: '#FFFFFF' }}>Вертикали</Button>
                    </Link>
                    <Link to='/geo-table'>
                        <Button sx={{ color: '#FFFFFF' }}>Гео</Button>
                    </Link>
                </Toolbar>
            </AppBar>
        </Box>
    );
}