import { MRT_ColumnDef, MRT_EditActionButtons, MRT_Row, MRT_TableOptions, MaterialReactTable, useMaterialReactTable, } from "material-react-table"
import { useEffect, useMemo, useState } from "react"
import { Box, Button, DialogActions, DialogContent, DialogTitle, IconButton, Tooltip } from "@mui/material"
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import {
    QueryClient,
    QueryClientProvider,
    useMutation,
    useQuery,
    useQueryClient,
} from 'react-query';
import axios from "axios";

type GeoType = {
    id: number,
    location_name: string
}

function GeoTable() {
    const [validateErrors, setValidateErrors] = useState<Record<string, string | undefined>>({})
    const [geo, setGeo] = useState([])

    useEffect(() => {
        axios.get('https://api.analcustdev.com/geo/get-all').then((res) => setGeo(res.data))
    }, [])

    const columns = useMemo<MRT_ColumnDef<GeoType>[]>(
        () => [
            {
                accessorKey: 'id',
                header: 'Id',
                enableEditing: false,

            },
            {
                accessorKey: 'location_name',
                header: 'Гео',
                enableEditing: true,
                muiEditTextFieldProps: {
                    required: false,
                    error: !!validateErrors?.geo,
                    helperText: validateErrors?.geo,

                    onFocus: () => {
                        setValidateErrors({
                            ...setValidateErrors,
                            geo: undefined
                        })
                    }
                },
            },
        ], [validateErrors]
    )

    const {
        data: fetchedGeo = [],
        isError: isLoadingGeoError,
        isFetching: isFetchingGeo,
        isLoading: isLoadingGeo,
    } = useGetGeo()

    const { mutateAsync: createGeo, isLoading: isCreatingGeo } = useCreateGeo();
    const { mutateAsync: updateGeo, isLoading: isUpdatingGeo } = useUpdateGeo();
    const { mutateAsync: deleteGeo, isLoading: isDeletingGeo } = useDeleteGeo();



    const handleCreateGeo: MRT_TableOptions<GeoType>['onCreatingRowSave'] = async ({
        values,
        table
    }) => {
        const newValidateErrors = validateGeo(values);
        if (Object.values(newValidateErrors).some((error) => error)) {
            setValidateErrors(newValidateErrors)
            return
        }
        setValidateErrors({})
        await createGeo(values)
        table.setCreatingRow(null)
    }

    const handleSaveGeo: MRT_TableOptions<GeoType>['onEditingRowSave'] = async ({
        values,
        table,
    }) => {
        const newValidateErrors = validateGeo(values)
        if (Object.values(newValidateErrors).some((error) => error)) {
            setValidateErrors(newValidateErrors)
            return
        }
        setValidateErrors({})
        await updateGeo(values)
        table.setEditingRow(null)
    }

    const openDeleteConfirmModal = (row: MRT_Row<GeoType>) => {
        if (window.confirm('Вы уверены, что хотите удалить эту локацию?')) {
            deleteGeo(row.original.id)
        }
    }

    const table = useMaterialReactTable({
        columns,
        data: fetchedGeo,
        createDisplayMode: 'modal',
        editDisplayMode: 'modal',
        enableEditing: true,
        // getRowId: (row: Questions) => row.id,
        muiToolbarAlertBannerProps: isLoadingGeoError
            ? {
                color: 'error',
                children: 'Ошибка загрузки данных'
            }
            : undefined,
        muiTableContainerProps: {
            sx: {
                minHeight: '500px'
            }
        },
        onCreatingRowCancel: () => setValidateErrors({}),
        onCreatingRowSave: handleCreateGeo,
        onEditingRowCancel: () => setValidateErrors({}),
        onEditingRowSave: handleSaveGeo,

        renderCreateRowDialogContent: ({ table, row, internalEditComponents }) => (
            <>
                <DialogTitle variant="h3">Создать новую локацию</DialogTitle>
                <DialogContent
                    sx={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}
                >
                    {internalEditComponents}
                </DialogContent>
                <DialogActions>
                    <MRT_EditActionButtons variant="text" table={table} row={row} />
                </DialogActions>
            </>
        ),

        renderEditRowDialogContent: ({ table, row, internalEditComponents }) => (
            <>
                <DialogTitle variant="h3">Изменить локацию</DialogTitle>
                <DialogContent
                    sx={{ display: 'flex', flexDirection: 'column', gap: '1.5rem' }}
                >
                    {internalEditComponents}
                </DialogContent>
                <DialogActions>
                    <MRT_EditActionButtons variant="text" table={table} row={row} />
                </DialogActions>
            </>
        ),
        renderRowActions: ({ row, table }) => (
            <Box sx={{ display: 'flex', gap: '1rem' }}>
                <Tooltip title="Изменить">
                    <IconButton onClick={() => table.setEditingRow(row)}>
                        <EditIcon />
                    </IconButton>
                </Tooltip>
                <Tooltip title="Удалить">
                    <IconButton color="error" onClick={() => openDeleteConfirmModal(row)}>
                        <DeleteIcon />
                    </IconButton>
                </Tooltip>
            </Box>
        ),
        renderTopToolbarCustomActions: ({ table }) => (
            <Button
                variant="contained"
                onClick={() => {
                    table.setCreatingRow(true)
                }}
            >
                Создать новую локацию
            </Button>
        ),
        state: {
            isLoading: isLoadingGeo,
            isSaving: isCreatingGeo || isUpdatingGeo || isDeletingGeo,
            showAlertBanner: isLoadingGeoError,
            showProgressBars: isFetchingGeo
        },
    })

    function useUpdateGeo() {
        const url: string = 'https://api.analcustdev.com/geo/edit'
        const queryClient = useQueryClient();
        return useMutation({
            mutationFn: async (geo: GeoType) => {
                await axios({
                    method: 'PUT',
                    url: url,
                    data: {
                        id: geo.id,
                        location_name: geo.location_name,
                    }
                })
            },

            onMutate: (newGeoInfo: GeoType) => {
                queryClient.setQueryData(['geo'], (prevGeo: any) =>
                    prevGeo?.map((prevGeo: GeoType) =>
                        prevGeo.id === newGeoInfo.id ? newGeoInfo : prevGeo,
                    ),
                );
            },
        });
    }

    function useCreateGeo() {
        const url: string = 'https://api.analcustdev.com/geo/create'
        const queryClient = useQueryClient();
        return useMutation({
            mutationFn: async (geo: GeoType) => {
                //zapros budet tuta
                // await new Promise((resolve) => setTimeout(resolve, 1000));
                // return Promise.resolve();

                await axios({
                    method: 'POST',
                    url: url,
                    data: {
                        location_name: geo.location_name,

                    }
                }
                )
            },

            onMutate: (newGeoInfo: GeoType) => {
                queryClient.setQueryData(
                    ['geo'],
                    (prevGeo: any) =>
                        [
                            ...prevGeo,
                            {
                                ...newGeoInfo,
                                id: (Math.random() + 1)
                            }
                        ] as GeoType[]
                )
            }
        })
    }

    return (
        <MaterialReactTable table={table} />
    )
}



function useGetGeo() {
    const url: string = 'https://api.analcustdev.com/geo/get-all'

    return useQuery<GeoType[]>({
        queryKey: ['geo'],
        queryFn: async () => {
            const res = await axios({
                method: 'GET',
                url: url,

            })
            return Promise.resolve(res.data)
        },
        refetchOnWindowFocus: false,
    })
}



function useDeleteGeo() {
    const queryClient = useQueryClient()
    return useMutation({
        mutationFn: async (geoId: number) => {
            await axios({
                method: 'DELETE',
                url: `https://api.analcustdev.com/geo/remove/${geoId}`,
            })
        },
        onMutate: (geoId: number) => {
            queryClient.setQueryData(['geo'], (prevGeo: any) =>
                prevGeo?.filter((geo: GeoType) => geo.id !== geoId),
            )
        },
    })
}

const queryClient = new QueryClient();

const GeoTableWithProviders = () => (
    <QueryClientProvider client={queryClient}>
        <GeoTable />
    </QueryClientProvider>
)

export default GeoTableWithProviders;

const validateRequired = (value: string) => !!value.length

function validateGeo(geo: GeoType) {
    return {
        geo: !validateRequired(geo.location_name)
            ? 'Geo is required' : '',

    }
}