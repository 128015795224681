import axios from "axios"
import { styled } from '@mui/material/styles';
import { useEffect, useState } from "react"
import List from '@mui/material/List';
import { ListItem, ListItemText, Typography, Box, ListItemButton, Button } from "@mui/material";
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { Link } from "react-router-dom";
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';

interface questionsResponse { id: number, name: string }
interface geoResponse { id: number, location_name: string }
interface verticalResponse { id: number, vertical_name: string }

interface questionsResponse extends Array<questionsResponse> { }
interface geoResponse extends Array<geoResponse> { }
interface verticalResponse extends Array<verticalResponse> { }

export default function MainScreen() {
    const [questions, setQuestions] = useState<questionsResponse>()
    const [error, setError] = useState<unknown>()
    // const [searchString, setSearchString] = useState<string>('')
    const [geos, setGeos] = useState<geoResponse>()
    const [geoFilter, setGeoFilter] = useState<any>(0)
    const [verticals, setVerticals] = useState<verticalResponse>()
    const [verticalFilter, setVerticalFilter] = useState<any>(0)
    const url: string = 'https://api.analcustdev.com/question/get-all'


    const filterQuestionByVertical = async (id: any) => {
        try {
            const res = await axios.get(`https://api.analcustdev.com/question/by-vertical/${verticalFilter}`).then(
                (res) => setQuestions(res.data.questions)
            )

        }
        catch (error) {
            console.error(error)
            setError(error)
        }
    }

    const filterQuestionByGeo = async (id: any) => {
        try {
            const res = await axios.get(`https://api.analcustdev.com/question/by-geo/${id}`).then((res) =>
                setQuestions(res.data.questions))
            console.log(questions)


        }
        catch (error) {
            console.error(error)
            setError(error)
        }
    }

    const getQuestions = async () => {
        try {
            const res = await axios.get(url).then((res) => setQuestions(res.data.questions))

        }
        catch (error) {
            console.error(error)
            setError(error)
        }
    };

    useEffect(() => {
        getQuestions()
        axios.get('https://api.analcustdev.com/vertical/get-all').then((res) => setVerticals(res.data))
        axios.get('https://api.analcustdev.com/geo/get-all').then((res) => setGeos(res.data))
    }, [])

    const ErrorMessage = styled('div')(({ theme }) => ({
        backgroundColor: theme.palette.error.main,
        height: '400px',
        textAlign: 'center',
        fontSize: 120,
        color: 'white',
        marginTop: 150
    }));

    return (
        <>
            <Typography sx={{ mt: 5, mb: 5, ml: 5 }} variant="h4" component='div'>
                Вопросы
            </Typography>
            <TextField
                value={geoFilter}
                select
                variant="filled"
                onChange={geoFilter || verticalFilter !== 'All' ? (e) => {

                    setGeoFilter(e.target.value)
                    filterQuestionByGeo(e.target.value)
                } : () => getQuestions()}
            >
                <MenuItem value={0} >
                    All
                </MenuItem>
                {geos?.map((item) => (
                    <MenuItem key={item.id} value={item.location_name}>
                        {item.location_name}
                    </MenuItem>
                ))}
            </TextField>
            <TextField
                select
                value={verticalFilter}
                onChange={geoFilter || verticalFilter !== 'All' ? (e) => {
                    setVerticalFilter(e.target.value)
                    filterQuestionByVertical(e.target.value)
                } : () => getQuestions()}
                helperText="Vertical filter"
                variant="filled"
            >
                <MenuItem value={0} >
                    All
                </MenuItem>
                {verticals?.map((item) => (
                    <MenuItem key={item.id} value={item.vertical_name}>
                        {item.vertical_name}
                    </MenuItem>
                ))}
            </TextField>
            {/* <TextField
                value={searchString}
                sx={{ width: '500px' }}
                label="Search"
                onChange={(e) => {
                    setSearchString(e.target.value)
                    console.log(searchString)
                }} /> */}
            <Box sx={{ width: '90%', bgcolor: 'background.paper', columnCount: 4, ml: 13 }}>
                <List>
                    {questions?.map((item: any, index: number) => (
                        <Link key={item.id} to={`answer/${item.id}`}>
                            <ListItem key={item.id} disablePadding >
                                <ListItemButton>
                                    <p>{index + 1}</p><ArrowRightIcon></ArrowRightIcon>
                                    <ListItemText primary={item.name} />
                                </ListItemButton>
                            </ListItem>
                        </Link>
                    ))}
                </List>
                {<Button sx={{ position: 'absolute', top: '150px', right: '100px', background: '#35c20e', color: '#FFFFFF' }} onClick={() => {
                    axios({
                        method: 'POST',
                        url: verticalFilter === 'All' && geoFilter === 'All' ? `https://api.analcustdev.com/excel/download` : geoFilter !== 'All' ? `https://api.analcustdev.com/excel/download?geo=${geoFilter}` : verticalFilter !== 'All' ? `https://api.analcustdev.com/excel/download?vertical=${verticalFilter}` : `https://api.analcustdev.com/excel/download?geo=${geoFilter}&vertical=${verticalFilter}`,
                        responseType: 'blob'
                    }).then((res) => {
                        const href = URL.createObjectURL(res.data)

                        const link = document.createElement('a')
                        link.href = href
                        link.setAttribute('download', 'file.xlsx')
                        document.body.appendChild(link)
                        link.click()

                        document.body.removeChild(link)
                        URL.revokeObjectURL(href)
                    })
                }} >Скачать вопросы</Button>}
            </Box>
            {error && <ErrorMessage>{error?.toString()}</ErrorMessage>}
        </>
    )
}