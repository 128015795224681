import { MRT_ColumnDef, MRT_EditActionButtons, MRT_Row, MRT_TableOptions, MaterialReactTable, useMaterialReactTable, } from "material-react-table"
import { useEffect, useMemo, useState } from "react"
import { Questions } from "./createData"
import { Box, Button, DialogActions, DialogContent, DialogTitle, IconButton, Tooltip } from "@mui/material"
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import {
    QueryClient,
    QueryClientProvider,
    useMutation,
    useQuery,
    useQueryClient,
} from 'react-query';
import axios from "axios";



function QuestionsTable() {
    const [validateErrors, setValidateErrors] = useState<Record<string, string | undefined>>({})
    const [vertical, setVertical] = useState([])
    const [geo, setGeo] = useState([])

    useEffect(() => {
        axios.get('https://api.analcustdev.com/geo/get-all').then((res) => setGeo(res.data))
        axios.get('https://api.analcustdev.com/vertical/get-all').then((res) => setVertical(res.data))
        console.log('verticals: ', vertical)

    }, [])

    const columns = useMemo<MRT_ColumnDef<Questions>[]>(
        () => [
            {
                accessorKey: 'id',
                header: 'Id',
                enableEditing: false,

            },
            {
                accessorKey: 'name',
                header: 'Вопрос',
                enableEditing: true,
                muiEditTextFieldProps: {
                    required: false,
                    error: !!validateErrors?.question,
                    helperText: validateErrors?.question,

                    onFocus: () => {
                        setValidateErrors({
                            ...setValidateErrors,
                            question: undefined
                        })
                    }
                },
            },
            {
                accessorKey: 'vertical',
                header: 'Вертикаль',
                enableEditing: true,
                // editSelectOptions: vertical.map((e: any) => e.vertical_name),
                muiEditTextFieldProps: {
                    required: false,
                    error: !!validateErrors?.vertical,
                    helperText: validateErrors?.vertical,

                    onFocus: () => {
                        setValidateErrors({
                            ...setValidateErrors,
                            vertical: undefined
                        })
                    }
                },
            },
            {
                accessorKey: 'geo',
                header: 'Гео',
                enableEditing: true,
                muiEditTextFieldProps: {
                    required: false,
                    error: !!validateErrors?.vertical,
                    helperText: validateErrors?.vertical,

                    onFocus: () => {
                        setValidateErrors({
                            ...setValidateErrors,
                            vertical: undefined
                        })
                    }
                },
            },
        ], [validateErrors]
    )

    const {
        data: fetchedQuestions = [],
        isError: isLoadingQuestionsError,
        isFetching: isFetchingQuestions,
        isLoading: isLoadingQuestions,
    } = useGetQuestion()

    const { mutateAsync: createQuestion, isLoading: isCreatingQuestion } = useCreateQuestion();
    const { mutateAsync: updateQuestion, isLoading: isUpdatingQuestion } = useUpdateQuestion();
    const { mutateAsync: deleteQuestion, isLoading: isDeletingQuestion } = useDeleteQuestion();



    const handleCreateQuestion: MRT_TableOptions<Questions>['onCreatingRowSave'] = async ({
        values,
        table
    }) => {
        const newValidateErrors = validateQuestion(values);
        if (Object.values(newValidateErrors).some((error) => error)) {
            setValidateErrors(newValidateErrors)
            return
        }
        setValidateErrors({})
        await createQuestion(values)
        table.setCreatingRow(null)
    }

    const handleSaveQuestion: MRT_TableOptions<Questions>['onEditingRowSave'] = async ({
        values,
        table,
    }) => {
        const newValidateErrors = validateQuestion(values)
        if (Object.values(newValidateErrors).some((error) => error)) {
            setValidateErrors(newValidateErrors)
            return
        }
        setValidateErrors({})
        await updateQuestion(values)
        table.setEditingRow(null)
    }

    const openDeleteConfirmModal = (row: MRT_Row<Questions>) => {
        if (window.confirm('Вы уверены, что хотите удалить этот вопрос?')) {
            deleteQuestion(row.original.id)
        }
    }

    const table = useMaterialReactTable({
        columns,
        data: fetchedQuestions,
        createDisplayMode: 'modal',
        editDisplayMode: 'modal',
        enableEditing: true,
        // getRowId: (row: Questions) => row.id,
        muiToolbarAlertBannerProps: isLoadingQuestionsError
            ? {
                color: 'error',
                children: 'Ошибка загрузки данных'
            }
            : undefined,
        muiTableContainerProps: {
            sx: {
                minHeight: '500px'
            }
        },
        onCreatingRowCancel: () => setValidateErrors({}),
        onCreatingRowSave: handleCreateQuestion,
        onEditingRowCancel: () => setValidateErrors({}),
        onEditingRowSave: handleSaveQuestion,

        renderCreateRowDialogContent: ({ table, row, internalEditComponents }) => (
            <>
                <DialogTitle variant="h3">Создать новый вопрос</DialogTitle>
                <DialogContent
                    sx={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}
                >
                    {internalEditComponents}
                </DialogContent>
                <DialogActions>
                    <MRT_EditActionButtons variant="text" table={table} row={row} />
                </DialogActions>
            </>
        ),

        renderEditRowDialogContent: ({ table, row, internalEditComponents }) => (
            <>
                <DialogTitle variant="h3">Изменить вопрос</DialogTitle>
                <DialogContent
                    sx={{ display: 'flex', flexDirection: 'column', gap: '1.5rem' }}
                >
                    {internalEditComponents}
                </DialogContent>
                <DialogActions>
                    <MRT_EditActionButtons variant="text" table={table} row={row} />
                </DialogActions>
            </>
        ),
        renderRowActions: ({ row, table }) => (
            <Box sx={{ display: 'flex', gap: '1rem' }}>
                <Tooltip title="Изменить">
                    <IconButton onClick={() => table.setEditingRow(row)}>
                        <EditIcon />
                    </IconButton>
                </Tooltip>
                <Tooltip title="Удалить">
                    <IconButton color="error" onClick={() => openDeleteConfirmModal(row)}>
                        <DeleteIcon />
                    </IconButton>
                </Tooltip>
            </Box>
        ),
        renderTopToolbarCustomActions: ({ table }) => (
            <Button
                variant="contained"
                onClick={() => {
                    table.setCreatingRow(true)
                }}
            >
                Создать новый вопрос
            </Button>
        ),
        state: {
            isLoading: isLoadingQuestions,
            isSaving: isCreatingQuestion || isUpdatingQuestion || isDeletingQuestion,
            showAlertBanner: isLoadingQuestionsError,
            showProgressBars: isFetchingQuestions
        },
    })

    function useUpdateQuestion() {
        const url: string = 'https://api.analcustdev.com/question/edit'
        const queryClient = useQueryClient();
        return useMutation({
            mutationFn: async (question: Questions) => {
                await axios({
                    method: 'PUT',
                    url: url,
                    data: {
                        id: question.id,
                        name: question.name,
                        vertical: vertical.filter((e: any) => question.vertical === e.vertical_name).map((e: any) => e.id)[0],
                        geo: geo.filter((e: any) => question.geo === e.location_name).map((e: any) => e.id)[0]
                    }
                })
            },

            onMutate: (newQuestionInfo: Questions) => {
                queryClient.setQueryData(['questions'], (prevQuestions: any) =>
                    prevQuestions?.map((prevQuestion: Questions) =>
                        prevQuestion.id === newQuestionInfo.id ? newQuestionInfo : prevQuestion,
                    ),
                );
            },
        });
    }

    function useCreateQuestion() {
        const url: string = 'https://api.analcustdev.com/question/create'
        const queryClient = useQueryClient();
        return useMutation({
            mutationFn: async (question: Questions) => {
                //zapros budet tuta
                // await new Promise((resolve) => setTimeout(resolve, 1000));
                // return Promise.resolve();

                await axios({
                    method: 'POST',
                    url: url,
                    data: {
                        name: question.name,
                        geo: geo.filter((e: any) => question.geo === e.location_name).map((e: any) => e.id)[0],
                        vertical: vertical.filter((e: any) => question.vertical === e.vertical_name).map((e: any) => e.id)[0],

                    }
                }
                )
            },

            onMutate: (newQuestionInfo: Questions) => {
                queryClient.setQueryData(
                    ['questions'],
                    (prevQuestions: any) =>
                        [
                            ...prevQuestions,
                            {
                                ...newQuestionInfo,
                                id: (Math.random() + 1)
                            }
                        ] as Questions[]
                )
            }
        })
    }

    return (
        <MaterialReactTable table={table} />
    )
}



function useGetQuestion() {
    const url: string = 'https://api.analcustdev.com/question/get-all'

    return useQuery<Questions[]>({
        queryKey: ['questions'],
        queryFn: async () => {
            const res = await axios({
                method: 'GET',
                url: url,

            })
            return Promise.resolve(res.data.questions)
        },
        refetchOnWindowFocus: false,
    })
}



function useDeleteQuestion() {
    const queryClient = useQueryClient()
    return useMutation({
        mutationFn: async (questionId: number) => {
            await axios({
                method: 'DELETE',
                url: `https://api.analcustdev.com/question/remove/${questionId}`,
            })
        },
        onMutate: (questionId: number) => {
            queryClient.setQueryData(['questions'], (prevQuestions: any) =>
                prevQuestions?.filter((question: Questions) => question.id !== questionId),
            )
        },
    })
}

const queryClient = new QueryClient();

const QuestionTableWithProviders = () => (
    <QueryClientProvider client={queryClient}>
        <QuestionsTable />
    </QueryClientProvider>
)

export default QuestionTableWithProviders;

const validateRequired = (value: string) => !!value.length

function validateQuestion(question: Questions) {
    return {
        question: !validateRequired(question.name)
            ? 'Question is required' : '',

    }
}