import MainScreen from "./Screens/MainScreen/MainScreen";
import { Routes, Route } from "react-router-dom";
import AnswersScreen from "./Screens/AnswersScreen/AnswersScreen";
import QuestionTableWithProviders from "./Screens/Table/QuestionTable";
import Header from "./Screens/Components/Header";
import GeoTableWithProviders from "./Screens/Table/SubTables/GeoTables";
import VerticalTableWithProviders from "./Screens/Table/SubTables/VerticalTable";

export default function App() {
  return (
    <>
      <Header />
      <Routes>
        <Route path='/table' element={<QuestionTableWithProviders />} />
        <Route path='/geo-table' element={<GeoTableWithProviders />} />
        <Route path='/vertical-table' element={<VerticalTableWithProviders />} />
        <Route path='/' element={<MainScreen />} />
        <Route path="/answer/:answerId" element={<AnswersScreen />} />
      </Routes>
    </>
  )
}
