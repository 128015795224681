import { useEffect, useState } from "react"
import axios from "axios"
import { styled } from '@mui/material/styles';
import { useParams } from "react-router-dom"
import { Link } from "react-router-dom";
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
import { Button, ButtonGroup, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";

interface handwrittenAnswerType {
    id: number
    questionId: number
    response_text: string
    createdAt: string
    updatedAt: string
}

interface handwrittenAnswerType extends Array<handwrittenAnswerType> { }

interface answerResponse {
    id: number
    name: string
    createdAt: string
    updatedAt: string
    handwrittenAnswer: handwrittenAnswerType[]
}

interface answerResponse extends Array<answerResponse> { }

export default function AnswersScreen() {
    const { answerId } = useParams()
    const [answers, setAnswers] = useState<answerResponse>()
    const [handwrittenAnswers, setHandWrittenAnswers] = useState<handwrittenAnswerType>()
    const [error, setError] = useState<unknown>()
    const url: string = `https://api.analcustdev.com/question/handwrittenAnswer/${answerId}`
    const downloadURL: string = `https://api.analcustdev.com/excel/download`

    const ErrorMessage = styled('div')(({ theme }) => ({
        backgroundColor: theme.palette.error.main,
        height: '400px',
        textAlign: 'center',
        fontSize: 120,
        color: 'white',
        marginTop: 150
    }));

    useEffect(() => {
        const fetchQuestionAndAnswers = async () => {
            try {
                const answersResponse = await axios.get(url);
                setAnswers(answersResponse.data);
                setHandWrittenAnswers(answersResponse.data.handwrittenAnswers)
            } catch (error) {
                console.error(error);
                setError(error)
            }
        };

        fetchQuestionAndAnswers();
    }, [answerId, url]);

    return (
        <div className="answer-screen-container">
            <Link to='/'>
                <ArrowBackRoundedIcon sx={{ height: 50, width: 50 }} />
            </Link>
            <ButtonGroup sx={{ position: 'absolute', right: '30px', top: '15px' }} variant="contained">
                <Button onClick={() => {
                    axios({
                        method: 'POST',
                        url: downloadURL,
                        responseType: 'blob'
                    }).then((res) => {
                        const href = URL.createObjectURL(res.data)

                        const link = document.createElement('a')
                        link.href = href
                        link.setAttribute('download', 'file.xlsx')
                        document.body.appendChild(link)
                        link.click()

                        document.body.removeChild(link)
                        URL.revokeObjectURL(href)
                    })
                }} >Скачать</Button>
            </ButtonGroup>
            {answers && (
                <>
                    <h1>{answers?.name}</h1>
                    <TableContainer >
                        <Table sx={{ width: '90%' }} >
                            <TableHead>~
                                <TableRow>
                                    <TableCell align="left">ID</TableCell>
                                    <TableCell align="left">Answer</TableCell>
                                    <TableCell align="left">Created At</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {handwrittenAnswers?.map((item) => (
                                    <TableRow key={item.id} >
                                        <TableCell component='th' scope="row">
                                            {item.id}
                                        </TableCell>
                                        <TableCell align="left">{item.response_text}</TableCell>
                                        <TableCell align="left">{item.createdAt}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    {error && <ErrorMessage>{error?.toString()}</ErrorMessage>}
                </>
            )}

        </div>
    )
}